//sezione account
import React, { useState, useEffect } from "react";
import firebase from "firebase";
import { formatData, getElemento, returnStato,checkDeleteS,checkWriteS } from "../../EngineClienti/engineClienti";
import BuildIcon from '@mui/icons-material/Build';
import GenerazionePdf from "../../GenerazionePdf";
import { IconButton,Tooltip } from "@mui/material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
const Dettagli = (props) => {

    const db = firebase.firestore(); //database firebase

    const [scadenza,setScadenza] = useState(null)
    const [documento,setDocumento] = useState(null)
    const [stato,setStato] = useState('')

    //edit valori
    
    
  const storage = firebase.storage();
      var storageRef = storage.ref();


      useEffect(()=>{
        if(scadenza!==null){
        if(scadenza.Oggetto){
            if(scadenza.Oggetto.includes('Documento')){
                var toGet = scadenza.Titolo.split(' ').slice(1).join(' ')
                setDocumento(toGet)
    
            }else{
              
            }
              
        }
        
      }
    
      },[scadenza]) //imposta oggetto o elementi in base se è un intervento o una man

      useEffect(()=>{
        if(props.scadenza!==null){
          console.log(props.scadenza)
          setScadenza(props.scadenza)
        }
      },[props.scadenza])


      useEffect(()=>{
        if(scadenza!==null&&props.user!==null){
          console.log(props.scadenza)
          setStato(returnStato(scadenza.Stato,scadenza.Data))
        }
      },[scadenza])

      function getSubScadenze(scadId, sede) {
        return new Promise((resolve, reject) => {
          const subScadenze = []; // Array per tutte le sottoscadenze
      
          db.collection("Users")
          .doc(props.user.Email)
          .collection("Sedi")
          .doc(sede)
          .collection("Manutenzioni")
          .doc(scadId)
          .collection('Manutenzioni')
            .get()
            .then((querySnapshot) => {
              const promises = querySnapshot.docs.map((actz) => {
                return db.collection('Manutenzioni').doc(actz.data().ref).get().then((act) => {
                  const subScadenza = {
                    key: act.id,
                    Titolo: act.data().Titolo || '',
                    Sede: act.data().Sede || '',
                    Descrizione: act.data().Descrizione || '',
                    Nome: act.data().Nome || '',
                    Cognome: act.data().Cognome || '',
                    Priorità: act.data().Priorità || '',
                    Oggetto: act.data().Oggetto || '',
                    Stato: act.data().Stato || '',
                    Cliente: act.data().Cliente || '',
                    Sede_cli: act.data().Sede_cli || '',
                    Elementi: act.data().Elementi || [],
                    Report: act.data().Report || '',
                    Esito: act.data().Esito || '',
                    SottoAttività: act.data().SottoAttività ? act.data().SottoAttività : false,
                    Indirizzo: act.data().Indirizzo || '',
                    Nome_AziendaCli: act.data().Nome_AziendaCli || '',
                    Nome_AziendaOff: act.data().Nome_AziendaOff || '',
                    InterventiPath: act.data().InterventiPath || '',
                    Categoria: act.data().Categoria || '',
                    Macrocategoria: act.data().Macrocategoria || '',
                    Operatore: props.user.Tipo === 'Lavoratore' || props.user.TipoSott === 'Lavoratore'
                      ? (act.data().Operatore ? act.data().Operatore : props.user.Tipo === 'SottoAccount' ? props.user.MasterAccount : props.auth.currentUser.email)
                      : (act.data().Operatore ? act.data().Operatore : props.user.Fornitore),
                    Archiviato: act.data().Archiviato ? act.data().Archiviato : false,
                    Type: act.data().Type ? act.data().Type : null,
                  };
      
                  subScadenze.push(subScadenza);
                });
              });
      
              // Una volta completate tutte le promesse, risolviamo la promessa principale con i dati raccolti
              Promise.all(promises)
                .then(() => resolve(subScadenze))
                .catch(reject); // In caso di errore, lo gestiamo qui
            })
            .catch(reject); // Gestione dell'errore nel caso in cui il get fallisca
        });
      }

      async function handleManutenzionePdf(manutenzioneReal) {
        try {
          props.setLoading(true)
          const manutenzione = { ...props.scadenza }; // Clona l'oggetto per evitare mutazioni indesiderate
          console.log(manutenzione)
          // Recupera i dati dell'operatore
          const operatoreDoc = await db.collection("Users").doc(manutenzione.Operatore).get();
          if (!operatoreDoc.exists) {
            throw new Error(`Operatore con ID ${manutenzione.Operatore} non trovato.`);
          }
          const operatoreData = operatoreDoc.data();
          const operatore = {
            Nome_Azienda: operatoreData.Nome_Azienda || '',
            Nome: operatoreData.Nome || '',
            Cognome: operatoreData.Cognome || '',
            Indirizzo: operatoreData.Indirizzo || '',
            Sede: operatoreData.Comune || '',
            Telefono: operatoreData.Numero || '',
          };
      
          // Recupera i dati del cliente
          const clienteDoc = await db.collection("Users").doc(manutenzione.Cliente).get();
          if (!clienteDoc.exists) {
            throw new Error(`Cliente con ID ${manutenzione.Cliente} non trovato.`);
          }
          const clienteData = clienteDoc.data();
          manutenzione.Nome_AziendaCli = clienteData.Nome_Azienda || '';
          manutenzione.Numero_telefono_cli = clienteData.Numero || '';
          manutenzione.Sede_cli = manutenzione.SedeName?manutenzione.SedeName!==''?manutenzione.SedeName:manutenzione.Sede:manutenzione.Sede || '';
      
          // Inizializza la lista degli elementi e delle sottoscadenze
          const listaElementi = [];
          const listaSottoscadenze = [];
      
          // Funzione per recuperare i dettagli di un elemento
          const getElementoDetails = async (elePath) => {
            const path = elePath.Elemento ? elePath.Elemento : elePath;
            const eleRef = await db.doc(path).get();
            if (!eleRef.exists) return null;
            const eleDataRef = await db.collection("Elementi").doc(eleRef.data().ref).get();
            if (!eleDataRef.exists) return null;
            return {
              Nome: eleDataRef.data().Nome || 'Nome non disponibile',
              Esito: elePath.Esito || 'Non specificato',
            };
          };
      
          // Recupera le sottoscadenze se SottoAttività è true
          if (manutenzione.SottoAttività) {
            console.log(manutenzione)
            const subScadenze = await getSubScadenze(manutenzione.Id, manutenzione.Cliente);
            console.log(subScadenze)
            // Recupera gli elementi per ciascuna sottoscadenza
            for (const sottoscadenza of subScadenze) {
              const elementiSotto = [];
              if (sottoscadenza.Elementi && sottoscadenza.Elementi.length > 0) {
                for (const ele of sottoscadenza.Elementi) {
                  const elementoDettagli = await getElementoDetails(ele);
                  if (elementoDettagli) {
                    elementiSotto.push(elementoDettagli);
                  }
                }
              }
      
              // Aggiungi la sottoscadenza alla lista
              listaSottoscadenze.push({
                ...sottoscadenza,
                Elementi: elementiSotto,
              });
            }
          }
      
          // Recupera gli elementi della manutenzione principale
          if (manutenzione.Oggetto && manutenzione.Oggetto.includes('Documento:')) {
            const toGet = manutenzione.Titolo.split(' ').slice(1).join(' ');
            manutenzione.Oggetto = toGet;
            listaElementi.push({
              Nome: toGet,
              Esito: manutenzione.Esito || 'Non specificato',
            });
          } else if (manutenzione.Elementi && manutenzione.Elementi.length > 0) {
            for (const ele of manutenzione.Elementi) {
              const elementoDettagli = await getElementoDetails(ele);
              if (elementoDettagli) {
                listaElementi.push(elementoDettagli);
              }
            }
          }
      
          // Genera il PDF passando tutte le informazioni necessarie
          GenerazionePdf.generaPdfManutenzione(
            manutenzione,
            operatore,
            listaElementi,
            listaSottoscadenze,
            props.setLoading
          );
        } catch (error) {
          console.error("Errore durante la generazione del PDF della manutenzione:", error);
          // Puoi gestire ulteriormente l'errore qui, ad esempio mostrando una notifica all'utente
        }
      } //funzione che genera la manutenzione pdf//funzione che genera la manutenzione pdf

      const [editTitolo, setEditTitolo] = useState(false); // Stato per edit
const [newTitolo, setNewTitolo] = useState(scadenza?.Titolo || ''); // Nuovo titolo

function salvaTitolo() {
  // Aggiorna il titolo su Firebase
  db.collection('Manutenzioni').doc(scadenza.Id).update({
    Titolo: newTitolo
  }).then(() => {
 //Aggiorna su calendario
 db.collection('Users').doc(scadenza.Cliente).collection('Annotazioni').doc(scadenza.Id).update({
Subject: newTitolo
}).then(()=>{
  db.collection('Users').doc(scadenza.Operatore).collection('Annotazioni').doc(scadenza.Id).update({
    Subject: newTitolo

  })
})

    // Aggiorna localmente il valore per il render
    setScadenza((prevScadenza) => ({ ...prevScadenza, Titolo: newTitolo }));
    setEditTitolo(false); // Esci dalla modalità di editing
    props.setScadenza((prevScadenza) => ({ ...prevScadenza, Titolo: newTitolo }))
    const listaE = JSON.parse(sessionStorage.getItem('scadenze'));
    if(listaE){
      var arr = [...listaE]
      var index = listaE.findIndex((e)=>e.Id===scadenza.Id)
      if(index!==-1){
          arr[index].Titolo = newTitolo
      }
      sessionStorage.setItem('scadenze', JSON.stringify(arr));
    }
 
  }).catch((error) => {
    console.error("Errore aggiornamento titolo:", error);
    alert('Errore durante il salvataggio');
  });
}
const [editDescrizione, setEditDescrizione] = useState(false); // Stato per edit
const [newDescrizione, setNewDescrizione] = useState(scadenza?.Descrizione || ''); // Nuova descrizione

function salvaDescrizione() {
  // Aggiorna la descrizione su Firebase
  db.collection('Manutenzioni').doc(scadenza.Id).update({
    Descrizione: newDescrizione
  }).then(() => {

    //Aggiorna su calendario
    db.collection('Users').doc(scadenza.Cliente).collection('Annotazioni').doc(scadenza.Id).update({
      Description: newDescrizione
    }).then(()=>{
      db.collection('Users').doc(scadenza.Operatore).collection('Annotazioni').doc(scadenza.Id).update({
        Description: newDescrizione

      })
    })
    // Aggiorna localmente il valore per il render
    setScadenza((prevScadenza) => ({ ...prevScadenza, Descrizione: newDescrizione }));
    setEditDescrizione(false); // Esci dalla modalità di editing
    props.setScadenza((prevScadenza) => ({ ...prevScadenza, Descrizione: newDescrizione }));
    
    const listaE = JSON.parse(sessionStorage.getItem('scadenze'));
    if(listaE){
      var arr = [...listaE];
      var index = listaE.findIndex((e)=>e.Id===scadenza.Id);
      if(index!==-1){
          arr[index].Descrizione = newDescrizione;
      }
      sessionStorage.setItem('scadenze', JSON.stringify(arr));
    }
  }).catch((error) => {
    console.error("Errore aggiornamento descrizione:", error);
    alert('Errore durante il salvataggio');
  });
}
const [editData, setEditData] = useState(false); // Stato per edit
const [newData, setNewData] = useState(scadenza?.Data || ''); // Nuova data

function salvaData() {
  console.log(props.elementi)
  // Aggiorna la data di scadenza su Firebase
  db.collection('Manutenzioni').doc(scadenza.Id).update({
    Data: newData
  }).then(() => {
    const dataInput = new Date(newData); // newData contiene il valore della nuova data in formato 'YYYY-MM-DD'

    const giorno = dataInput.getDate(); // Ottiene il giorno
    const mese = dataInput.getMonth() + 1; // Ottiene il mese (gennaio è 0, quindi +1)
    const anno = dataInput.getFullYear(); // Ottiene l'anno

    //aggiorna annotazione man e cli 
    db.collection('Users').doc(scadenza.Cliente).collection('Annotazioni').doc(scadenza.Id).update({
      Day: giorno,
      Month: mese,
      Year: anno
    }).then(()=>{
      db.collection('Users').doc(scadenza.Operatore).collection('Annotazioni').doc(scadenza.Id).update({
        Day: giorno,
        Month: mese,
        Year: anno
      })
    })

    //aggiorna data elementi
    if(scadenza.Elementi){
      scadenza.Elementi.forEach((elemento)=>{
        var path = elemento.Elemento?elemento.Elemento:elemento
          db.doc(path).get().then((doczz)=>{
            db.collection('Elementi').doc(doczz.data().ref).update({
              ProssimaScadenza: newData
            })

        
       
      })
    })}


    // Aggiorna localmente il valore per il render
    setScadenza((prevScadenza) => ({ ...prevScadenza, Data: newData }));
    setEditData(false); // Esci dalla modalità di editing
    props.setScadenza((prevScadenza) => ({ ...prevScadenza, Data: newData }));

    const listaE = JSON.parse(sessionStorage.getItem('scadenze'));
    if(listaE){
      var arr = [...listaE];
      var index = listaE.findIndex((e)=>e.Id===scadenza.Id);
      if(index!==-1){
          arr[index].Data = newData;
      }
      sessionStorage.setItem('scadenze', JSON.stringify(arr));
    }
  }).catch((error) => {
    console.error("Errore aggiornamento data di scadenza:", error);
    alert('Errore durante il salvataggio');
  });
}
const [editReport, setEditReport] = useState(false); // Stato per edit
const [newReport, setNewReport] = useState(scadenza?.Report || ''); // Nuove osservazioni

function salvaReport() {
  // Aggiorna il report su Firebase
  db.collection('Manutenzioni').doc(scadenza.Id).update({
    Report: newReport
  }).then(() => {
    // Aggiorna localmente il valore per il render
    setScadenza((prevScadenza) => ({ ...prevScadenza, Report: newReport }));
    setEditReport(false); // Esci dalla modalità di editing
    props.setScadenza((prevScadenza) => ({ ...prevScadenza, Report: newReport }));

    const listaE = JSON.parse(sessionStorage.getItem('scadenze'));
    if(listaE){
      var arr = [...listaE];
      var index = listaE.findIndex((e)=>e.Id===scadenza.Id);
      if(index!==-1){
          arr[index].Report = newReport;
      }
      sessionStorage.setItem('scadenze', JSON.stringify(arr));
    }
  }).catch((error) => {
    console.error("Errore aggiornamento osservazioni:", error);
    alert('Errore durante il salvataggio');
  });
}


    return props.user!==null? <div style={{paddingLeft:'1rem',height:'80vh',overflowY:'auto',width:'71vw'}}>
      {/*informazioni scadenza*/}
      {scadenza!==null?<div>
        <div style={{display:'flex',alignItems:'center',marginTop:'1rem'}}>
        <BuildIcon style={{color:'black'}}/>

      <h4 className="header-normal" style={{marginLeft:'5px'}}>Informazioni scadenza</h4>
        <Tooltip title='Scarica PDF riepilogativo'>
        <IconButton style={{width:'fit-content',height:'fit-content',marginLeft:'auto',marginRight:'2rem'}} onClick={()=>handleManutenzionePdf(scadenza)}>
        <PictureAsPdfIcon style={{ color: "#FF0060" }}/>
        </IconButton>
        </Tooltip>
  

      </div>
      {/*titolo*/}
      <div className="flex-row" style={{ width: '45vw' }}>
  <div style={{ marginTop: '1rem', height: '4rem', width: 'fit-content', maxWidth: '40vw' }}>
    {editTitolo === false ? (
      <h4 className="normal-text">{scadenza.Titolo}</h4>
    ) : (
      <input
        type="text"
        className="input-style"
        placeholder="Inserisci..."
        value={newTitolo}
        onChange={(e) => setNewTitolo(e.target.value)}
      />
    )}
    <h4 className="sub-text">Titolo</h4>
  </div>
  <div style={{ marginLeft: 'auto', width: 'fit-content' }}>
    {editTitolo === false ?checkWriteS(props.user.Permissions)===true?  (
      <IconButton style={{ width: 'fit-content' }} onClick={() => setEditTitolo(true)}>
        <EditIcon />
      </IconButton>
    ) :null: (
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: 5 }}>
        <IconButton style={{ width: 'fit-content', backgroundColor: 'lightgreen' }}>
          <CheckIcon onClick={() => { newTitolo !== '' ? salvaTitolo() : alert('Inserisci il nuovo titolo') }} style={{ color: 'green' }} />
        </IconButton>
        <IconButton onClick={() => { setEditTitolo(false); setNewTitolo(scadenza.Titolo); }} style={{ marginLeft: 5, backgroundColor: 'lightpink', width: 'fit-content' }}>
          <CloseIcon style={{ color: 'red' }} />
        </IconButton>
      </div>
    )}
  </div>
</div>
      {/*descrizione*/}
      <div className="flex-row" style={{ width: '45vw' }}>
  <div style={{ marginTop: '1rem', height: '4rem', width: 'fit-content', maxWidth: '40vw' }}>
    {editDescrizione === false ? (
      <h4 className="normal-text">{scadenza.Descrizione}</h4>
    ) : (
      <input
        type="text"
        className="input-style"
        placeholder="Inserisci..."
        value={newDescrizione}
        onChange={(e) => setNewDescrizione(e.target.value)}
      />
    )}
    <h4 className="sub-text">Descrizione</h4>
  </div>
  <div style={{ marginLeft: 'auto', width: 'fit-content' }}>
    {editDescrizione === false ?checkWriteS(props.user.Permissions)===true? (
      <IconButton style={{ width: 'fit-content' }} onClick={() => setEditDescrizione(true)}>
        <EditIcon />
      </IconButton>
    ):null : (
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: 5 }}>
        <IconButton style={{ width: 'fit-content', backgroundColor: 'lightgreen' }}>
          <CheckIcon onClick={() => { newDescrizione !== '' ? salvaDescrizione() : alert('Inserisci la nuova descrizione') }} style={{ color: 'green' }} />
        </IconButton>
        <IconButton onClick={() => { setEditDescrizione(false); setNewDescrizione(scadenza.Descrizione); }} style={{ marginLeft: 5, backgroundColor: 'lightpink', width: 'fit-content' }}>
          <CloseIcon style={{ color: 'red' }} />
        </IconButton>
      </div>
    )}
  </div>
</div>
            {/*sede*/}
      <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        <h4 className="normal-text">{scadenza.SedeName?scadenza.SedeName:scadenza.Sede}</h4>
    <h4 className="sub-text">Sede</h4>
        </div>
      </div>
                  {/*indirizzo*/}
                  <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        <h4 className="normal-text">{scadenza.Indirizzo}</h4>
    <h4 className="sub-text">Indirizzo</h4>
        </div>
      </div>
      {/*categoria*/}
      <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        <h4 className="normal-text">{scadenza.Categoria}</h4>
    <h4 className="sub-text">Categoria</h4>
        </div>
      </div>
                  {/*elemento*/}
                  <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        <h4 className="normal-text">{documento!==null?documento:scadenza.Oggetto}</h4>
    <h4 className="sub-text">Oggetto</h4>
        </div>
      </div>
                        {/*data*/}
                        <div className="flex-row" style={{ width: '45vw' }}>
  <div style={{ marginTop: '1rem', height: '4rem', width: 'fit-content', maxWidth: '40vw' }}>
    {editData === false ?checkWriteS(props.user.Permissions)===true?  (
      <h4 className="normal-text">{formatData(scadenza.Data)}</h4>
    ):null : (
      <input
        type="date"
        className="input-style"
        value={newData}
        onChange={(e) => setNewData(e.target.value)}
      />
    )}
    <h4 className="sub-text">Data di Scadenza</h4>
  </div>
  <div style={{ marginLeft: 'auto', width: 'fit-content' }}>
    {stato!=='Pianificata'?null:editData === false ? (
      <IconButton style={{ width: 'fit-content' }} onClick={() => setEditData(true)}>
        <EditIcon />
      </IconButton>
    ) : (
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: 5 }}>
        <IconButton style={{ width: 'fit-content', backgroundColor: 'lightgreen' }}>
          <CheckIcon onClick={() => { newData !== '' ? salvaData() : alert('Inserisci la nuova data') }} style={{ color: 'green' }} />
        </IconButton>
        <IconButton onClick={() => { setEditData(false); setNewData(scadenza.Data); }} style={{ marginLeft: 5, backgroundColor: 'lightpink', width: 'fit-content' }}>
          <CloseIcon style={{ color: 'red' }} />
        </IconButton>
      </div>
    )}
  </div>
</div>
                              {/*stato*/}
         <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        <h4 className="normal-text">{stato}</h4>
    <h4 className="sub-text">Stato</h4>
        </div>
      </div>
                                    {/*esito*/}
                                    <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        <h4 className="normal-text">{scadenza.Esito?scadenza.Esito:"Esito disponibile una volta evasa la scadenza"}</h4>
    <h4 className="sub-text">Esito complessivo</h4>
        </div>
      </div>
      {/*report*/}
      <div className="flex-row" style={{ width: '45vw' }}>
  <div style={{ marginTop: '1rem', height: '4rem', width: 'fit-content', maxWidth: '40vw' }}>
    {editReport === false ? checkWriteS(props.user.Permissions)===true? (
      <h4 className="normal-text">{scadenza.Report?scadenza.Report:'-'}</h4>
    ):null : (
      <input
        type="text"
        className="input-style"
        placeholder="Inserisci..."
        value={newReport}
        onChange={(e) => setNewReport(e.target.value)}
      />
    )}
    <h4 className="sub-text">Osservazioni</h4>
  </div>
  <div style={{ marginLeft: 'auto', width: 'fit-content' }}>
    {stato==='Conclusa'?editReport === false ? (
      <IconButton style={{ width: 'fit-content' }} onClick={() => setEditReport(true)}>
        <EditIcon />
      </IconButton>
    ) : (
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: 5 }}>
        <IconButton style={{ width: 'fit-content', backgroundColor: 'lightgreen' }}>
          <CheckIcon onClick={() => { newReport !== '' ? salvaReport() : alert('Inserisci le nuove osservazioni') }} style={{ color: 'green' }} />
        </IconButton>
        <IconButton onClick={() => { setEditReport(false); setNewReport(scadenza.Report); }} style={{ marginLeft: 5, backgroundColor: 'lightpink', width: 'fit-content' }}>
          <CloseIcon style={{ color: 'red' }} />
        </IconButton>
      </div>
    ):null}
  </div>
</div>
      </div>:null}
      <div style={{alignItems:'center',marginTop:'5vh',marginLeft:'auto',width:'fit-content'}}>
        </div>
    </div>:null
}

export default Dettagli;